import { dispatch } from 'store';
import { setBreadcrumb } from 'store/reducers/breadcrumb';
import { NavItemType } from 'types/menu';

export default function useBreadcrumbs() {
  return {
    setBreadcrumb: (show: boolean, datas: Array<NavItemType>) => {
      dispatch(
        setBreadcrumb({
          show,
          items: datas
        })
      );
    }
  };
}
